.layout {
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  color: '#ffffff';
}

.inlineCode {
  background-color: #1b242b;
}

#markdown table {
  border: 1px solid;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
#markdown th {
  border: 1px solid;
  background-color: #1b242b;
  color: white;
  border-color: black;
  height: 60px;
  padding: 0.7em;
  overflow: hidden;
}
#markdown td {
  border: 1px solid;
  padding: 0.7em;
  overflow: hidden;
}
#markdown tr:nth-child(even) {
  background-color: #f2f2f2;
}
