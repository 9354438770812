.yScrollBarWrapper-white {
  overflow-y: auto;
  --scrollbarBG: #00000021;
  --thumbBG: #ffffff;
}
.yScrollBarWrapper-white:hover {
  --thumbBG: #ffffffc2;
}
.yScrollBarWrapper-white::-webkit-scrollbar {
  width: 0.5em;
}
.yScrollBarWrapper-white {
  scrollbar-width: thin !important;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.yScrollBarWrapper-white::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.yScrollBarWrapper-white::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 4px;
  border: 2px solid var(--scrollbarBG) transparent;
}

.title_grey {
  color: #0000008a;
}

.width-box {
  border-left: 2px solid #00000031;
  margin-left: 2px;
  width: 1px;
  transition: width 0.3s ease-in-out;
}

.card-animation:hover .width-box {
  border-left: 0;
  border-radius: 0;
  width: 6em;
  transition: width 0.3s ease-in-out;
}

.one-line-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
